<template>
  <div class="layoutContainer">
    <div class="secondFloat">
      <el-form :inline="true" ref="formInline" :model="formInline" class="demo-form-inline">
        <el-form-item label="项目名称" prop="projectName">
          <el-input v-model="formInline.projectName" placeholder="请输入项目名称"></el-input>
        </el-form-item>
        <el-form-item label="负责人" prop="principal">
          <el-select v-model="formInline.principal">
            <el-option v-for="(item, index) in principalList" :label="item.realName" :key="index"  :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手册状态" prop="manualStatus">
          <el-select v-model="formInline.manualStatus">
            <el-option v-for="(item, index) of manualList" :key="index" :label="item.name" :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="立项时间范围">
          <el-date-picker type="date" placeholder="选择开始日期" v-model="formInline.begintime"></el-date-picker>
          <span class="line">至</span>
          <el-date-picker type="date" placeholder="选择结束日期" v-model="formInline.endtime"></el-date-picker>
        </el-form-item>
        <el-form-item label="风险状态" prop="riskstatus">
          <el-select v-model="formInline.riskstatus">
            <el-option v-for="(item, index) of riskList" :key="index" :label="item.name" :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-search">查询</el-button>
          <el-button plain @click="reset('formInline')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableDetail">
      <!-- <el-button type="primary" icon="el-icon-plus" @click="headelAdd()">新增</el-button> -->
      <el-table style="width:100%" border :data="resultList" :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
        <el-table-column label="项目名称" prop="projectName" width="170"></el-table-column>
        <el-table-column label="手册分类" prop="cnName" width="170"></el-table-column>
        <el-table-column label="负责人" prop="principalName" width="170"></el-table-column>
        <el-table-column label="开发状态" prop="manualStatus" width="180">
          <template slot-scope="{row}">
            <span v-if="row.manualStatus === 1">进行中</span>
            <span v-if="row.manualStatus === 2">暂停中</span>
            <span v-if="row.manualStatus === 3">已验收</span>
          </template>
        </el-table-column>
        <el-table-column label="节点时间" prop="deadlineTime" width="180" :formatter="dateFormat"></el-table-column>
        <el-table-column label="初稿时间" prop="firstDraftTime" width="180" :formatter="dateFormat"></el-table-column>
        <el-table-column label="终稿时间" prop="finalDraftTime" width="180" :formatter="dateFormat"></el-table-column>
        <el-table-column label="风险状态" prop="riskStatus" width="140">
          <template slot-scope="{row}">
            <span v-if="row.riskStatus === 1" style="color:#009933">无风险</span>
            <span v-if="row.riskStatus === 2" style="color:#cc0000">低风险</span>
            <span v-if="row.riskStatus === 3" style="color:#cc0000">中风险</span>
            <span v-if="row.riskStatus === 4" style="color:#cc0000">高风险</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="275">
          <template slot-scope="{row}">
            <el-button type="primary" size="mini" @click="headelDetail(row)">查看</el-button>
            <el-button type="primary" size="mini" @click="headelImage(row, '图片明细', '/imgDetail')">图片明细</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0" :total="total" :page.sync="currentPage" :limit.sync="pagesize" @pagination="dataList"/>
      <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" :before-close="handleCloseCreate">
        <el-form v-if="dialogStatus === 'detail'" ref='dataForm' :model="temp" label-position="center">
          <el-form-item label="项目名称" prop="projectName" :label-width="formLabelWidth">
            <el-input v-model="temp.projectName" readonly></el-input>
          </el-form-item>
          <el-form-item label="手册分类" prop="cnName" :label-width="formLabelWidth">
            <el-input v-model="temp.cnName" readonly></el-input>
          </el-form-item>
          <el-form-item label="负责人" prop="principalName" :label-width="formLabelWidth">
            <el-input v-model="temp.principalName" readonly></el-input>
          </el-form-item>
          <el-form-item label="开发状态" prop="manualStatus" :label-width="formLabelWidth">
            <el-input v-model="temp.manualStatus" readonly></el-input>
          </el-form-item>
          <el-form-item label="节点时间" prop="deadlineTime" :label-width="formLabelWidth">
            <el-date-picker type="date" v-model="temp.deadlineTime" readonly></el-date-picker>
          </el-form-item>
          <el-form-item label="初稿时间" prop="firstDraftTime" :label-width="formLabelWidth">
            <el-date-picker type="date" v-model="temp.firstDraftTime" readonly></el-date-picker>
          </el-form-item>
          <el-form-item label="终稿时间" prop="finalDraftTime" :label-width="formLabelWidth">
            <el-date-picker type="date" v-model="temp.finalDraftTime" readonly></el-date-picker>
          </el-form-item>
          <el-form-item label="风险状态" prop="riskStatus" :label-width="formLabelWidth">
            <el-input v-model="temp.riskStatus" readonly></el-input>
          </el-form-item>
        </el-form>
        <el-form v-if="dialogStatus === 'add'" ref='dataForm' :model="temp" label-position="center">
          <el-form-item label="项目名称" prop="projectId" :label-width="formLabelWidth">
            <el-select v-model="temp.projectId">
              <el-option v-for="(item, index) of projectList" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="手册分类" prop="manualType" :label-width="formLabelWidth">
            <el-select v-model="temp.manualType">
              <el-option v-for="(item, index) of manualTypeList" :key="index" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="负责人" prop="principal" :label-width="formLabelWidth">
            <el-select v-model="temp.principal">
              <el-option v-for="(item, index) of principalList" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开发状态" prop="manualStatus" :label-width="formLabelWidth">
            <el-select v-model="temp.manualStatus">
              <el-option v-for="(item, index) of manualList" :key="index" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="节点时间" prop="deadlineTime" :label-width="formLabelWidth">
            <el-date-picker type="date" v-model="temp.deadlineTime" placeholder="请选择节点时间"></el-date-picker>
          </el-form-item>
          <el-form-item label="初稿时间" prop="firstDraftTime" :label-width="formLabelWidth">
            <el-date-picker type="date" v-model="temp.firstDraftTime" placeholder="请选择初稿时间"></el-date-picker>
          </el-form-item>
          <el-form-item label="终稿时间" prop="finalDraftTime" :label-width="formLabelWidth">
            <el-date-picker type="date" v-model="temp.finalDraftTime" placeholder="请选择终稿时间"></el-date-picker>
          </el-form-item>
          <el-form-item label="风险状态" prop="riskStatus" :label-width="formLabelWidth">
            <el-select v-model="temp.riskStatus">
              <el-option v-for="(item, index) of riskList" :key="index" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <el-button type="primary" @click="addClick()">
              立即提交
            </el-button>
            <el-button @click="dialogFormVisible = false">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { sysServerUrl, cmsServerUrl, obj } from '@/assets/js/common.js'
import Pagination from '@/components/Pagination'
import { imageData, imageSearch, imageUser, imageProject, imageManualType, imageAdd } from '@/api/api.js'
import axios from 'axios'
export default {
  name: 'layoutContainer',
  components: { Pagination },
  data () {
    return {
      formInline: {
        projectName: '',
        principal: '',
        manualStatus: '',
        begintime: '',
        endtime: '',
        riskStatus: ''
      },
      temp: {
        id: '',
        projectId: '',
        manualType: '',
        principal: '',
        projectName: '',
        manualStatus: '',
        deadlineTime: '',
        firstDraftTime: '',
        finalDraftTime: '',
        riskStatus: ''
      },
      riskList: [
        { name: '无风险', code: 1 },
        { name: '低风险', code: 2 },
        { name: '中风险', code: 3 },
        { name: '高风险', code: 4 }
      ],
      manualList: [
        { name: '进行中', code: 1 },
        { name: '暂停中', code: 2 },
        { name: '已验收', code: 3 }
      ],
      principalList: [],
      projectList: [],
      manualTypeList: [],
      resultList: [],
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        detail: '项目明细',
        image: '图片详情'
      },
      pagesize: 10,
      currentPage: 1,
      total: 0,
      formLabelWidth: '100px'
    }
  },
  methods: {
    // 数据
    dataList () {
      var params = {
        page: this.currentPage ,
        limit: this.pagesize
      }
      imageData(params).then(res => {
        this.total = res.data.total
        this.resultList = res.data.data
      })
    },
    // 提交
    onSubmit () {
      var params = {
        page: this.currentPage, 
        limit: this.pagesize,
        projectName: this.formInline.projectName,
        principal: this.formInline.principal,
        manualStatus: this.formInline.manualStatus,
        riskStatus: this.formInline.riskStatus,
        begintime: this.formInline.begintime,
        endTime: this.formInline.endTime
      }
      imageSearch(params).then(res => {
        this.total = res.data.total
        this.resultList = res.data.data
      })
    },
    handleCloseCreate () {
      this.dialogFormVisible = false
      // this.$refs.temp.resetFields()
    },
    // 用户列表
    getUserList () {
      imageUser().then(res => {
        this.principalList = res.data.data
      })
    },
    // 项目列表
    getProjectList () {
      imageProject().then(res => {
        if (res.data !== null && res.data.code === 100) {
          this.projectList = res.data.data
        }
      })
    },
    // 手册分类
    getManualList () {
      imageManualType().then(res => {
        if (res.data !== null && res.data.code === 100) {
          this.manualTypeList = res.data.data
        }
      })
    },
    resetTemp () {
      this.temp = {
        id: '',
        projectId: '',
        manualType: '',
        principal: '',
        projectName: '',
        manualStatus: '',
        deadlineTime: '',
        firstDraftTime: '',
        finalDraftTime: '',
        riskStatus: ''
      }
    },
    reset (formInline) {
      if (this.$refs[formInline].resetFields() !== undefined) {
        this.$refs[formInline].resetFields()
      }
      this.dataList()
    },
    getStatusCode () {
      for (var i = 0; i < this.manualList.length; i++) {
        if (this.manualList[i].code === this.temp.manualStatus) {
          this.temp.manualStatus = this.manualList[i].name
        }
      }
      for (var j = 0; j < this.riskList.length; j++) {
        if (this.riskList[j].code === this.temp.riskStatus) {
          this.temp.riskStatus = this.riskList[j].name
        }
      }
    },
    // 新增
    headelAdd () {
      // this.resetTemp()
      this.dialogFormVisible = true
      // this.dialogStatus = 'add'
      this.dialogStatus = 'add'
    },
    addClick () {
      var params = new URLSearchParams()
      this.updatedCode()
      params.append('projectId', this.temp.projectId)
      params.append('cnName', this.temp.cnName)
      params.append('manualType', this.temp.manualType)
      params.append('principal', this.temp.principal)
      params.append('deadlineTime', obj.getMyDate(this.temp.deadlineTime))
      params.append('firstDraftTime', obj.getMyDate(this.temp.firstDraftTime))
      params.append('finalDraftTime', obj.getMyDate(this.temp.finalDraftTime))
      params.append('riskStatus', this.temp.riskStatus)
      imageAdd(params).then(res => {
        if (res.data.ret === 'success') {
          this.$message({
            message: res.data.msg,
            type: 'success'
          })
          this.dataList()
          this.dialogFormVisible = false
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 查看
    headelDetail (row) {
      this.resetTemp()
      this.temp = Object.assign({}, row)
      this.getStatusCode()
      this.dialogFormVisible = true
      this.dialogStatus = 'detail'
    },
    // 图片明细
    headelImage (row, name, url) {
      let title =row.projectName+"#"+row.cnName+"#"+name
      let  purl= url +"/"+row.id+"/"+row.manualVersion
      obj.tabsClick(title, purl)
      // this.$router.push({ path: purl})
 
      this.$router.push({ name:'imgDetail', params: { manualId: row.id, manualVersion: row.manualVersion }})
      //this.$router.push({ path: url, query: { manualId: row.id, manualVersion: row.manualVersion, imgName: row.cnName, projectName: row.projectName } })
    },
    dateFormat (row, column, cellValue, index) {
      if (cellValue !== null) {
        const date = new Date(parseInt(cellValue))
        const Y = date.getFullYear() + '-'
        const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
        const D = date.getDate() < 10 ? '0' + date.getDate() + '' : date.getDate() + ''
        return Y + M + D
      }
    }
  },
  mounted () {
    this.dataList()
    this.getUserList()
    this.getProjectList()
    this.getManualList()
  }
}
</script>
<style>
  .el-input.is-disabled .el-input__inner {
    background-color: transparent;
    border-color: #E4E7ED;
    color: #606266;
    cursor: not-allowed;
  }
</style>
